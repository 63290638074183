require('./bootstrap');

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { Inertia } from '@inertiajs/inertia';
// import ScriptX from 'vue-scriptx'
import PublicLayout from "@/Layouts/Guest.vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || '';

createInertiaApp({
    // title: (title) => `${appName} - ${title}`,
    resolve: async (name) => {
        let page = (await import(`./Pages/${name}.vue`)).default;
        page.layout ??= PublicLayout;
        return page;
    },
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            // .use(ScriptX)
            .mixin({ methods: { route } })
            .mount(el);
    },
});

InertiaProgress.init({ color: '#ee7f4e' });

Inertia.on('navigate', (event) => {
    console.log('page_location', event.detail.page.url);
    const interpreter = event.detail.page.props?.interpreter?.name || '';
    console.log('interpreter', interpreter);
    /*
    gtag('config', 'G-NTN7BBP15B', {
        'page_location': event.detail.page.url
    });
    gtag('set', 'content_group', event.detail.page.component);
    */
    gtag('event', 'page_view', {
        'event_category': interpreter,
        'event_label': event.detail.page.component,
        'value': 1,
        'page_location': event.detail.page.url,
        'content_group': interpreter,
        'content_component': event.detail.page.component,
        'interpreter': interpreter
    });
    (adsbygoogle = window.adsbygoogle || []).push({});
})
